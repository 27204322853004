import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  Box,
  Button,
  Stack,
  Typography,
  ExpandMoreIcon,
  Checkbox,
  FormControlLabel,
  Paper,
  ArrowRightAltIcon,
} from "../../mui";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { snackbarActions } from "../../Store/snackbar";
import AdminService from "../../services/AdminService";
import UserRoleMangement from "./UserRoleMangement";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `2px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowRightAltIcon sx={{ fontSize: "1rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "#f8e6eb",
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    justifyContent: "center",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "50%",
  maxHeight: "90vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 1,
  overflow: "auto",
};

const tableRowData = [
  "Tracking Number",
  "Client",
  "Number of Samples",
  "Created By",
];

const EditUserAccess = ({ row, allClientsProjects }) => {
  const [expanded, setExpanded] = useState(false);
  const [processingClientSave, setProcessingClientSave] = useState(false);
  const [clients_projects, setClients_projects] = useState(
    row.clients_projects || {}
  );

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate: mutateUserAccess } = useMutation({
    mutationFn: (body) => AdminService.editUserAccessAndRoles(body),
    onSuccess: (data) => {
      setExpanded(false);
      queryClient.invalidateQueries({ queryKey: ["get_all_users_access"] });
      dispatch(
        snackbarActions.showNotification({
          snackbarOpen: true,
          snackbarType: "success",
          snackbarMessage: data,
        })
      );
    },
    onError: (error) => {
      console.log(error);
      dispatch(
        snackbarActions.showNotification({
          snackbarOpen: true,
          snackbarType: "error",
          snackbarMessage:
            typeof error?.response?.data?.detail === "string"
              ? error.response.data.detail
              : "Something went wrong.",
        })
      );
    },
    onSettled: () => {
      setProcessingClientSave(false);
    },
  });

  const handleAccChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleSave = () => {
    const new_clients_projects = Object.keys(clients_projects).reduce(
      (acc, curr) => {
        if (clients_projects[curr]?.length) {
          acc[curr] = clients_projects[curr];
        }
        return acc;
      },
      {}
    );
    setProcessingClientSave(true);
    mutateUserAccess({ ...row, ["clients_projects"]: new_clients_projects });
  };
  const handleCheckChangeProj = (client_name, proj) => {
    let tempArr = clients_projects[client_name]
      ? clients_projects[client_name]
      : [];
    if (tempArr.includes(proj)) {
      tempArr = clients_projects[client_name].filter((el) => el !== proj);
    } else tempArr.push(proj);
    setClients_projects((prev) => {
      return {
        ...prev,
        [client_name]: tempArr,
      };
    });
  };
  const handleCheckChangeClient = (e, client_name) => {
    if (e.target.checked) {
      setClients_projects((prev) => {
        return {
          ...prev,
          [client_name]: allClientsProjects[client_name],
        };
      });
    } else
      setClients_projects((prev) => {
        return {
          ...prev,
          [client_name]: [],
        };
      });
  };

  const handleCheckAll = () => {
    setClients_projects(allClientsProjects);
  };
  const handleUnCheckAll = () => {
    setClients_projects({});
  };

  return (
    <Stack sx={style}>
      <Box
        overflow="auto"
        component={Paper}
        variant="outlined"
        style={{ overflow: "visible" }}
      >
        <Stack direction="column" m={2} gap={1}>
          <Typography variant="h6">
            <b>Full Name: </b>
            {row.full_name}
          </Typography>
          <Typography variant="h6">
            <b>Username: </b> {row.username}
          </Typography>
          <div>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleAccChange("panel1")}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography variant="h3">Update User Roles</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <UserRoleMangement
                  group={row?.user_type}
                  username={row?.username}
                  setExpanded={setExpanded}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleAccChange("panel2")}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography variant="h3">Update Client Access</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack
                  direction="row"
                  gap={2}
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Stack gap={1} width="40%">
                    <Button variant="outlined" onClick={handleCheckAll}>
                      Check All
                    </Button>
                    <Button variant="outlined" onClick={handleUnCheckAll}>
                      Uncheck All
                    </Button>
                    <Button
                      disabled={processingClientSave}
                      variant="contained"
                      onClick={handleSave}
                      color="success"
                    >
                      Save Access
                    </Button>
                  </Stack>
                  <Paper
                    sx={{
                      width: "60%",
                      height: "65vh",
                      overflow: "auto",
                      padding: "2px 2rem",
                    }}
                    elevation={0}
                    variant="outlined"
                  >
                    {Object.keys(allClientsProjects).map(
                      (client_name, index) => (
                        <Stack
                          direction="column"
                          key={index}
                          sx={{ width: "90%", padding: "5px" }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={
                                  clients_projects
                                    ? JSON.stringify(
                                        clients_projects[client_name]?.sort()
                                      ) ===
                                      JSON.stringify(
                                        allClientsProjects[client_name]?.sort()
                                      )
                                    : false
                                }
                                name={client_name}
                                indeterminate={
                                  clients_projects[client_name]?.length > 0 &&
                                  JSON.stringify(
                                    clients_projects[client_name]?.sort()
                                  ) !==
                                    JSON.stringify(
                                      allClientsProjects[client_name]?.sort()
                                    )
                                }
                                onChange={(e) =>
                                  handleCheckChangeClient(e, client_name)
                                }
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontWeight: "bold",
                                  },
                                }}
                              />
                            }
                            label={
                              <Typography
                                variant="h6"
                                letterSpacing="1.5px"
                                fontWeight="bold"
                              >
                                {client_name}
                              </Typography>
                            }
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              ml: "2rem",
                            }}
                          >
                            {allClientsProjects[client_name].map((proj) => (
                              <FormControlLabel
                                key={proj}
                                control={
                                  <Checkbox
                                    checked={
                                      clients_projects[client_name]?.includes(
                                        proj
                                      )
                                        ? true
                                        : false
                                    }
                                    onChange={() =>
                                      handleCheckChangeProj(client_name, proj)
                                    }
                                    name={proj}
                                  />
                                }
                                label={proj}
                              />
                            ))}
                          </Box>
                        </Stack>
                      )
                    )}
                  </Paper>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </div>
        </Stack>
      </Box>
    </Stack>
  );
};

export default EditUserAccess;
