import React from "react";
import { Redirect, Route, useRouteMatch } from "react-router-dom";
import UserAppManager from "../../../src/pages/UserAppManager";
import ExternalServicesManager from "../../../src/pages/ExternalManageComponents/ExternalServicesManager";
import ParentWrapper from "../../components/ParentWrapper";
import PrivateRoute from "../../components/PrivateRoute";
import ExternalUserAppManager from "../../../src/pages/ExternalUserAppManager";
import RequestTable from "../RequestTable";
import AccessManagementWrapper from "../Admin/AccessManagementWrapper";

const ParentAdmin = (props) => {
  let { path } = useRouteMatch();
  return (
    <ParentWrapper path={path}>
      {/* <PrivateRoute exact path={`${path}/external-users`}>
        <ExternalUserAppManager />
      </PrivateRoute>
      <PrivateRoute exact path={`${path}/internal-users`}>
        <UserAppManager />
      </PrivateRoute> */}
      <PrivateRoute exact path={`${path}/user-management`}>
        <AccessManagementWrapper />
      </PrivateRoute>
      {/* <PrivateRoute exact path={`${path}/access-requests`}>
        <RequestTable></RequestTable>
      </PrivateRoute> */}
      <Route>
        <Redirect to={`${path}/user-management`} />
      </Route>
    </ParentWrapper>
  );
};

export default ParentAdmin;
