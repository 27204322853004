import AccessManagement from "./AccessManagement";
import { QueryClient, QueryClientProvider } from "react-query";
import UIWrapper from "../../components/UIWrapper";

const queryClient = new QueryClient();

function AccessManagementWrapper(props) {
  return (
    <>
      <UIWrapper title="User Management" paper>
        <QueryClientProvider client={queryClient}>
          <AccessManagement />
        </QueryClientProvider>
      </UIWrapper>
    </>
  );
}

export default AccessManagementWrapper;
