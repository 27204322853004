import { Auth, API } from "aws-amplify";
import axios from "axios";

const ADMIN_API_BASE_URL = process.env.MAIN_API;

class AdminService {
  async addToGroup(username, group) {
    let apiName = "AdminQueries";
    let path = "/addUserToGroupAdmin";
    let myInit = {
      body: {
        username: `${username}`,
        groupname: `${group}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await API.post(apiName, path, myInit);
  }

  async removeFromGroup(username, groupName) {
    let apiName = "AdminQueries";
    let path = "/removeUserFromGroup";
    let myInit = {
      body: {
        username: `${username}`,
        groupname: `${groupName}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await API.post(apiName, path, myInit);
  }

  async listUsersInGroup(groupName) {
    // let nextToken;

    let apiName = "AdminQueries";
    let path = "/listUsersInGroupAdmin";
    let myInit = {
      queryStringParameters: {
        groupname: `${groupName}`,
        limit: "60",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    // const { NextToken, ...rest } =   API.get(apiName, path, myInit);
    // nextToken = NextToken;
    return await API.get(apiName, path, myInit);
  }

  async getUser(username) {
    // let nextToken;

    let apiName = "AdminQueries";
    let path = "/getUser";
    let myInit = {
      queryStringParameters: {
        username: `${username}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    return await API.get(apiName, path, myInit);
  }

  async listAllUserGroups(username) {
    // let nextToken;

    let apiName = "AdminQueries";
    let path = "/listGroupsForUser";
    let myInit = {
      queryStringParameters: {
        username: `${username}`,
        limit: "60",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    // const { NextToken, ...rest } =   API.get(apiName, path, myInit);
    // nextToken = NextToken;
    return await API.get(apiName, path, myInit);
  }

  async allUsers() {
    // let nextToken;
    let apiName = "AdminQueries";
    let path = "/listUsers";
    let myInit = {
      queryStringParameters: {
        limit: "60",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    // const { NextToken, ...rest } =   API.get(apiName, path, myInit);
    // nextToken = NextToken;
    return await API.get(apiName, path, myInit);
  }

  async createBulletin(data) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    let body = data;

    return await axios.post(
      ADMIN_API_BASE_URL + "/bulletin/update-bulletin-info",
      body,
      myInit
    );
  }

  async getBulletinData(page) {
    let myInit = {
      params: {
        view: `${page}`,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ADMIN_API_BASE_URL + "/bulletin/bulletin-info",
      myInit
    );
  }
  async getUserProfileData() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ADMIN_API_BASE_URL + "/user/current-user-info",
      myInit
    );
  }

  async getAllUsersDetails() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    return await axios.get(ADMIN_API_BASE_URL + "/admin/all/users", myInit);
  }
  async editUserAccessAndRoles(body) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    const response = await axios.post(
      ADMIN_API_BASE_URL + "/admin/all/clients_projects",
      body,
      myInit
    );
    return response.data;
  }

  async getAllClientsProjects() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ADMIN_API_BASE_URL + "/admin/all/clients_projects",
      myInit
    );
  }
  async getAllRoles() {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(ADMIN_API_BASE_URL + "/admin/roles", myInit);
  }
  async getRoleCreationData(role) {
    let myInit = {
      params: {
        role,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    return await axios.get(
      ADMIN_API_BASE_URL + "/admin/existing_roles_subservices",
      myInit
    );
  }
  async createNewRole(role, group, subservices) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    const body = { role, group, subservices };

    return await axios.post(
      ADMIN_API_BASE_URL + "/admin/role/create",
      body,
      myInit
    );
  }
  async updateRole(role, group, subservices) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    const body = { role, group, subservices };

    return await axios.post(
      ADMIN_API_BASE_URL + "/admin/role/update",
      body,
      myInit
    );
  }

  async getUserRoleAccess(username, group) {
    let myInit = {
      params: {
        username,
        group,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.get(
      ADMIN_API_BASE_URL + "/admin/user_roles_subservices",
      myInit
    );
  }

  async updateUserRoleAccess(body) {
    let myInit = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await axios.post(
      ADMIN_API_BASE_URL + "/admin/user_access/update",
      body,
      myInit
    );
  }
}

export default new AdminService();
