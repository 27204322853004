import ServicesManager from "./userManageComponents/ServicesManager";
import { QueryClient, QueryClientProvider } from "react-query";
import UIWrapper from "../components/UIWrapper";
const queryClient = new QueryClient();

function UserAppManager(props) {
  return (
    <>
      <UIWrapper title="User Management" paper>
        <QueryClientProvider client={queryClient}>
          <ServicesManager service={props.service} />
        </QueryClientProvider>
      </UIWrapper>
    </>
  );
}

export default UserAppManager;
