import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { styled } from "@mui/material/styles";
import {
  Stack,
  Link,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField,
} from "../../mui";
import { tableCellClasses } from "@mui/material/TableCell";
import EditUserAccess from "./EditUserAccess";
import CreateRole from "./CreateRole";
import AdminService from "../../services/AdminService";
import { useDispatch } from "react-redux";
import { snackbarActions } from "../../Store/snackbar";
import { isEmpty } from "lodash";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: "1rem",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ClientProjectCell = ({ client_project }) => {
  const [expanded, setExpanded] = useState(false);
  const [cellData, setCellData] = useState([]);

  useEffect(() => {
    const temp = [];
    if (Object.keys(client_project)?.length)
      Object.entries(client_project).forEach((item) => {
        item[1].forEach((el) => temp.push([item[0], el]));
      });

    setCellData(temp);
  }, [client_project]);

  return (
    <div style={{ minWidth: "200px" }}>
      {expanded
        ? cellData.map((el, i) => <div key={i}>{el.join(" - ")}</div>)
        : cellData
            .slice(0, 3)
            .map((el, i) => <div key={i}>{el.join(" - ")}</div>)}
      {cellData.length > 3 && (
        <Link
          type="button"
          component="button"
          sx={{ fontSize: "inherit" }}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? "view less" : "view more"}
        </Link>
      )}
    </div>
  );
};

const RolesCell = ({ roles }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div style={{ minWidth: "150px" }}>
      {expanded
        ? roles.map((el, i) => <div key={i}>{el}</div>)
        : roles.slice(0, 3).map((el, i) => <div key={i}>{el}</div>)}
      {roles.length > 3 && (
        <Link
          type="button"
          component="button"
          sx={{ fontSize: "inherit" }}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? "view less" : "view more"}
        </Link>
      )}
    </div>
  );
};
const tableRowData = ["Full Name", "User Name", "Role(s)", "Client-Project"];

const AccessManagement = (props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userType, setUserType] = useState("internal");
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openCreateRoleModal, setOpenCreateRoleModal] = useState(false);
  const [userData, setUserData] = useState({});
  const [allClientsProjects, setAllClientsProjects] = useState({});

  const [pageLarge, setPageLarge] = useState(0);
  const [rowsPerPageLarge, setRowsPerPageLarge] = useState(6);

  const handleChangePageLarge = (event, newPage) => {
    setPageLarge(newPage);
  };

  const handleChangeRowsPerPageLarge = (event) => {
    setRowsPerPageLarge(+event.target.value);
    setPageLarge(0);
  };

  const dispatch = useDispatch();
  const {
    isLoading,
    data: allUsers,
    isFetching,
  } = useQuery(
    ["get_all_users_access"],
    () => AdminService.getAllUsersDetails().then((resp) => resp.data),

    { staleTime: 5 * 60 * 1000 }
  );
  const handleClose = () => {
    setOpenEditModal(false);
    setUserData({});
  };

  const handleEditUser = (row) => {
    setUserData(row);
    setOpenEditModal(true);
  };

  useEffect(() => {
    if (searchQuery.trim())
      setFilteredUsers(
        allUsers?.filter(
          (item) =>
            item?.full_name
              ?.toLowerCase()
              .includes(searchQuery.trim().toLowerCase()) &&
            item?.user_type === userType
        )
      );
    else
      setFilteredUsers(
        allUsers?.filter((item) => item?.user_type === userType)
      );
  }, [userType, allUsers, searchQuery]);

  useEffect(() => {
    AdminService.getAllClientsProjects()
      .then((resp) => {
        const sorted_clients = Object.keys(resp.data).sort();
        const data = sorted_clients.reduce((acc, curr) => {
          acc[curr] = resp.data[curr].sort();
          return acc;
        }, {});
        setAllClientsProjects(data);
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              typeof err?.response?.data?.detail === "string"
                ? err.response.data.detail
                : "Something went wrong.",
          })
        );
      });
  }, []);

  return (
    <>
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        padding-right="2rem"
      >
        <Stack direction="row" gap={2}>
          <FormControl sx={{ minWidth: 150 }} size="small">
            <InputLabel id="select-type">User Type</InputLabel>
            <Select
              labelId="select-type"
              id="select-small-gp"
              value={userType}
              label="User Type"
              onChange={(e) => setUserType(e.target.value)}
            >
              <MenuItem value="internal">Internal</MenuItem>
              <MenuItem value="external">External</MenuItem>
            </Select>
          </FormControl>
          {allUsers?.length > 0 && (
            <TextField
              id="outlined-basic"
              label="Search User"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              size="small"
              sx={{ width: 250 }}
            />
          )}
        </Stack>
        <Button
          variant="contained"
          color="blue"
          onClick={() => setOpenCreateRoleModal(true)}
        >
          Create New Role
        </Button>
      </Stack>
      <TableContainer style={{ marginTop: "15px" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {tableRowData.map((row, index) => (
                <StyledTableCell
                  align="left"
                  key={index}
                  style={{ fontWeight: "bold" }}
                >
                  {row}
                </StyledTableCell>
              ))}

              <StyledTableCell align="left"></StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {filteredUsers
              ?.slice(
                pageLarge * rowsPerPageLarge,
                pageLarge * rowsPerPageLarge + rowsPerPageLarge
              )
              ?.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {row.full_name}
                  </StyledTableCell>
                  <StyledTableCell>{row.username}</StyledTableCell>
                  <StyledTableCell>
                    {isEmpty(row.roles) ? (
                      "None/Null"
                    ) : (
                      <RolesCell roles={row.roles} />
                    )}
                  </StyledTableCell>
                  <StyledTableCell sx={{ pr: "5px" }}>
                    {isEmpty(row.clients_projects) ? (
                      "None/Null"
                    ) : (
                      <ClientProjectCell
                        client_project={row.clients_projects}
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: "140px", px: "5px" }}>
                    <Button
                      variant="contained"
                      onClick={() => handleEditUser(row)}
                    >
                      Edit User Access
                    </Button>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>

        <TablePagination
          rowsPerPageOptions={[6, 10, 25]}
          component="div"
          count={filteredUsers?.length || 0}
          rowsPerPage={rowsPerPageLarge}
          page={pageLarge}
          onPageChange={handleChangePageLarge}
          onRowsPerPageChange={handleChangeRowsPerPageLarge}
        />
      </TableContainer>
      {isLoading ? (
        <span>Loading..</span>
      ) : isFetching ? (
        <span>Refreshing...</span>
      ) : null}
      <Modal
        open={openEditModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Stack>
          <EditUserAccess
            allClientsProjects={allClientsProjects}
            row={userData}
          />
        </Stack>
      </Modal>
      <Modal
        open={openCreateRoleModal}
        onClose={() => setOpenCreateRoleModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Stack>
          <CreateRole setOpenCreateRoleModal={setOpenCreateRoleModal} />
        </Stack>
      </Modal>
    </>
  );
};

export default AccessManagement;
