import { Redirect, Route, useRouteMatch } from "react-router-dom";
import UserAppManager from "../../../src/pages/UserAppManager";
import ParentWrapper from "../../components/ParentWrapper";
import PrivateRoute from "../../components/PrivateRoute";
import ManifestLayoutView from "../Manifest/ManifestLayoutView";
import ManifestMapper from "../Manifest/ManifestMapper";
import NewProject from "../Projects/NewProject";
import UpdateProject from "../Projects/UpdateProject";
import ManageClients from "../ClientOnboarding/ManageClients";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

const ProjectManagement = (props) => {
  const { path } = useRouteMatch();
  return (
    <QueryClientProvider client={queryClient}>
      <ParentWrapper path={path}>
        <PrivateRoute exact path={`${path}/manifest/layout`}>
          <ManifestLayoutView />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}/projects/mapping`}>
          <UpdateProject />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}/projects/new`}>
          <NewProject />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}/client/onboarding`}>
          <ManageClients />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}/design/manifest`}>
          <ManifestMapper />
        </PrivateRoute>
        {/* <PrivateRoute exact path={`${path}/users`}>
          <UserAppManager service={`${path}`} />
        </PrivateRoute> */}
        <PrivateRoute path={`${path}/projects`}>
          <Redirect to={`${path}/projects/mapping`} />
        </PrivateRoute>
        <Route>
          <Redirect to={`${path}/projects/mapping`} />
        </Route>
      </ParentWrapper>
    </QueryClientProvider>
  );
};

export default ProjectManagement;
