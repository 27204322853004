import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Checkbox,
  TableRow,
  Divider,
  Button,
  CircularProgress,
} from "../../mui";
import { styled } from "@mui/material/styles";
import AdminService from "../../services/AdminService";
import { useDispatch } from "react-redux";
import { snackbarActions } from "../../Store/snackbar";

const style = {
  position: "absolute",
  display: "flex",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "80%",
  maxHeight: "95%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: "1px 50px",
  overflow: "auto",
  borderRadius: "5px",
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const tableHeaders = ["Sub-Service Name", "Is Assigned", "Permission Level"];

const CreateRole = ({ setOpenCreateRoleModal }) => {
  const [roleGroup, setRoleGroup] = useState("internal");
  const [tab, setTab] = useState("one");
  const [roleName, setRoleName] = useState("");
  const [rolesList, setRolesList] = useState([]);
  const [newRoleData, setNewRoleData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [duplicateError, setDuplicateError] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const handleRoleNameChange = (e, child) => {
    if (tab === "two") {
      if (child.props?.group && child.props?.value) {
        setRoleGroup(child.props.group);
        setRoleName(child.props.value);
        setTableData([]);
        setLoading(true);
        AdminService.getRoleCreationData(child.props.value)
          .then((resp) => {
            setLoading(false);
            setTableData(
              child.props.group === "internal"
                ? resp.data
                : resp.data.filter((el) => el.is_external)
            );
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      } else {
        setRoleGroup("");
        setRoleName("");
        setTableData([]);
      }
    } else {
      setRoleName(e.target.value);
      setDuplicateError(
        rolesList.some((el) => el?.role === e.target.value?.trim())
      );
    }
  };
  const handleRowChange = (new_value, index, field_name) => {
    const copyData = JSON.parse(JSON.stringify(tableData));
    copyData[index][field_name] = new_value;
    setTableData(copyData);
  };
  const handleGroupChange = (e) => {
    setRoleGroup(e.target.value);
    setTableData(
      e.target.value === "internal"
        ? newRoleData
        : newRoleData.filter((el) => el.is_external)
    );
  };

  const handleSave = () => {
    setProcessing(true);
    const saveMethod =
      tab === "two" ? AdminService.updateRole : AdminService.createNewRole;
    saveMethod(roleName, roleGroup, tableData)
      .then((resp) => {
        setProcessing(false);
        setOpenCreateRoleModal(false);
        setRoleGroup("internal");
        setTableData([]);
        setTab("one");
        setRolesList([]);
        setRoleName("");
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: resp.data,
          })
        );
      })
      .catch((err) => {
        setProcessing(false);
        console.log(err);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              typeof err?.response?.data?.detail === "string"
                ? err.response.data.detail
                : "Something went wrong.",
          })
        );
      });
  };

  const handleTabChange = (e, new_value) => {
    setRoleName("");
    const role_gp = new_value === "two" ? "" : "internal";
    setRoleGroup(role_gp);
    setTableData(new_value === "two" ? [] : newRoleData);
    setTab(new_value);
  };

  useEffect(() => {
    setLoading(true);
    AdminService.getRoleCreationData()
      .then((resp) => {
        setLoading(false);
        setNewRoleData(resp.data);
        setTableData(
          roleGroup === "internal"
            ? resp.data
            : resp.data.filter((el) => el.is_external)
        );
      })
      .catch((err) => {
        setLoading(false);
        dispatch(
          snackbarActions.showNotification({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage:
              typeof err?.response?.data?.detail === "string"
                ? err.response.data.detail
                : "Something went wrong.Unable to fetch role creation data.",
          })
        );
      });
    AdminService.getAllRoles()
      .then((resp) => {
        setRolesList(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Stack sx={style}>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="primary tabs example"
      >
        <Tab value="one" label="Create Role" />
        <Tab value="two" label="Edit Role" />
      </Tabs>
      <Box
        component={Paper}
        variant="outlined"
        p=".5rem 1rem"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div>
          <Stack direction="row" mt="5px">
            <Typography width="12%" minWidth="100px" margin="auto">
              {tab === "one" ? "Role Name:" : "Select Role:"}
            </Typography>
            {tab === "one" ? (
              <TextField
                id="outlined-basic-rn"
                label="Enter a Unique Role Name"
                variant="outlined"
                required={true}
                error={duplicateError}
                value={roleName}
                onChange={handleRoleNameChange}
                helperText={
                  duplicateError ? "The entered Role Name already exists." : ""
                }
                size="small"
                sx={{
                  minWidth: 200,
                  "& .MuiInputBase-input": {
                    paddingY: "6px",
                  },
                }}
              />
            ) : (
              <FormControl sx={{ minWidth: 200 }} size="small">
                <InputLabel id="select-role"></InputLabel>
                <Select
                  labelId="select-role"
                  id="select-role-name"
                  value={roleName}
                  label=""
                  onChange={(e, child) => handleRoleNameChange(e, child)}
                  sx={{
                    "& .MuiInputBase-input": {
                      paddingY: "6px",
                    },
                  }}
                >
                  {rolesList.map((item, index) => (
                    <MenuItem
                      key={index}
                      group={item?.group}
                      value={item?.role}
                    >
                      {item?.role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Stack>
          <Stack direction="row" mt="5px">
            <Typography
              color={tab === "two" ? "text.disabled" : "initial"}
              width="12%"
              minWidth="100px"
              margin="auto"
            >
              Role Group:{" "}
            </Typography>
            {tab === "one" ? (
              <FormControl
                sx={{
                  minWidth: 200,
                }}
                size="small"
              >
                <InputLabel id="select-group"></InputLabel>
                <Select
                  labelId="select-group"
                  id="select-role-gp"
                  variant="filled"
                  value={roleGroup}
                  label=""
                  onChange={handleGroupChange}
                  sx={{
                    "& .MuiInputBase-input": {
                      paddingY: "6px",
                    },
                  }}
                >
                  <MenuItem value="internal">Internal</MenuItem>
                  <MenuItem value="external">External</MenuItem>
                </Select>
              </FormControl>
            ) : (
              <TextField
                id="selected_rname_gp"
                value={roleGroup}
                size="small"
                variant="filled"
                sx={{
                  minWidth: 200,
                  "& .MuiInputBase-input": {
                    paddingY: "6px",
                  },
                }}
                disabled
              />
            )}
          </Stack>
        </div>
        <Divider width="100%" sx={{ margin: "10px 0" }} />
        <Typography sx={{ mb: 1, fontSize: 15, fontWeight: "800" }}>
          Mange Role Access
        </Typography>

        <Stack direction="column" justifyContent="center">
          <TableContainer
            variant="outlined"
            sx={{ margin: "8px 0", maxHeight: "300px" }}
            component={Paper}
          >
            <Table
              size="small"
              sx={{ minWidth: 500 }}
              aria-label="customized table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {tableHeaders.map((row, index) => (
                    <TableCell
                      align="left"
                      key={index}
                      sx={{ fontWeight: "bold", minWidth: "80px" }}
                    >
                      {row}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {tableData.map((row, index) => (
                  <StyledTableRow key={row.subservice_id}>
                    <TableCell component="th" scope="row">
                      {row.subservice_name}
                    </TableCell>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={row.is_assigned}
                        onChange={(e) =>
                          handleRowChange(
                            e.target.checked,
                            index,
                            "is_assigned"
                          )
                        }
                        inputProps={{
                          "aria-labelledby": "checkbox",
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <FormControl sx={{ minWidth: 110 }} size="small">
                        <InputLabel id="select-permission-level"></InputLabel>
                        <Select
                          labelId="select-permission-level"
                          id="permission-level-id"
                          value={row.permission_level}
                          label=""
                          sx={{
                            "& .MuiInputBase-input": {
                              fontSize: "x-small",
                              paddingY: "1px",
                            },
                          }}
                          variant="filled"
                          onChange={(e) =>
                            handleRowChange(
                              e.target.value,
                              index,
                              "permission_level"
                            )
                          }
                        >
                          <MenuItem value="VIEW">VIEW</MenuItem>
                          <MenuItem value="EXEC">EXEC</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            {!tableData?.length && (
              <Stack
                width="100%"
                height="150px"
                justifyContent="center"
                alignItems="center"
                margin="auto"
              >
                {loading ? <CircularProgress /> : "No data"}
              </Stack>
            )}
          </TableContainer>
          <Button
            variant="contained"
            disabled={
              !(tableData?.length > 0 && roleName.trim()) ||
              duplicateError ||
              processing
            }
            onClick={handleSave}
            margin="5px"
            color="success"
          >
            {processing ? "Saving" : "Save"}
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};

export default CreateRole;
